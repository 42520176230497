import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import { capitalize } from '@tools/string-tools'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: 'relative',
    display: 'block',
    borderStyle: 'solid',
    borderColor: theme.palette.yellow.main,
    borderWidth: theme.spacing(0, 0, 0, 4),
    borderRadius: theme.spacing(4, 0, 0, 4),
    marginBottom: (props) => {
      return !props.noMarginBottom ? theme.spacing(10) : 0
    },

    boxShadow: theme.customShadow.card,
    background: 'white',
    '&:focus': {
      borderColor: theme.palette.background.focus,
    },
  },
  stageCard: {
    display: 'inline-block',
    marginBottom: 0,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
    },
  },
  barRight: {
    borderWidth: theme.spacing(0, 4, 0, 0),
    borderRadius: theme.spacing(0, 4, 4, 0),
  },
  red: {
    borderColor: theme.palette.red.main,
  },
  grey: {
    borderColor: theme.palette.grey.main,
  },
  cardInner: {
    padding: theme.spacing(5, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  cardInnerHV32: {
    padding: theme.spacing(8),
  },
  cardInnerH32V24: {
    padding: theme.spacing(6, 8),
  },
  transparentCard: {
    background: 'rgba(255,255,255,0.95)',
  },
  bindLeft: {
    '& $cardInner': {
      padding: theme.spacing(8, 15, 10, 4),
      [theme.breakpoints.up('lg')]: {
        maxWidth: theme.spacing(134),
        marginLeft: 'auto',
      },
    },
  },
  bindLeftAuto: {
    '& $cardInner': {
      padding: theme.spacing(8, 15, 10, 4),
      minWidth: theme.spacing(65),
      maxWidth: theme.spacing(81),
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.spacing(134),
      },
    },
  },
  bindRight: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(50% + ${theme.spacing(46)}px)`,
    },
    marginLeft: 'auto',
    '& $cardInner': {
      padding: theme.spacing(8, 15, 14, 15),
      maxWidth: theme.spacing(176),
    },
  },
  bindSplit: {
    '& $cardInner': {
      padding: theme.spacing(10, 5, 10, 5),
      maxWidth: theme.spacing(120),
      margin: '0 auto',
      [theme.breakpoints.up('xxl')]: {
        padding: theme.spacing(10),
        maxWidth: theme.spacing(150),
      },
    },
  },
  answerBorder: {
    zIndex: 3,
  },
  answerBorder1: {
    zIndex: 2,
    margin: '-5px 25px 0 10px',
    position: 'relative',
    display: 'block',
    borderWidth: '0 9px 0 0',
    borderColor: theme.palette.red.main,
    borderStyle: 'solid',
    borderBottomRightRadius: '9px',
    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.23)',
    background: 'white',
    height: '10px',
    '$cardRoot:focus ~ &': {
      borderRightColor: theme.palette.background.focus,
    },
  },
  answerBorder2: {
    zIndex: 1,
    margin: '-5px 45px 0 20px',
    position: 'relative',
    display: 'block',
    borderWidth: '0 9px 0 0',
    borderColor: theme.palette.red.main,
    borderStyle: 'solid',
    borderBottomRightRadius: '9px',
    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.23)',
    background: 'white',
    height: '10px',
    '$cardRoot:focus ~ &': {
      borderRightColor: theme.palette.background.focus,
    },
  },
}))

function Card({ className, children, ...props }) {
  const {
    type: CardType,
    bar,
    barOrientation,
    bind,
    transparentCard,
    stage,
    link,
    answerBorder,
    noMarginBottom,
    cardInner,
    ...other
  } = props
  const classes = useStyles({
    noMarginBottom,
  })
  const allClassNames = clsx(
    className,
    classes.cardRoot,
    classes[`bar${capitalize(barOrientation)}`],
    classes[`bind${capitalize(bind)}`],
    {
      [classes.transparentCard]: transparentCard,
      [classes[bar]]: bar,
      [classes.stageCard]: stage,
    }
  )

  function renderComponent() {
    if (link) {
      return (
        <Link
          data-track-content
          data-tracking-id={link}
          data-testid={'default-card'}
          className={clsx(allClassNames, {
            [classes.answerBorder]: answerBorder !== 'none',
          })}
          to={link}
          {...other}
        >
          <div
            className={clsx({
              [classes.cardInner]: cardInner === 'default',
              [classes.cardInnerHV32]: cardInner === 'HV32',
              [classes.cardInnerH32V24]: cardInner === 'H32V24',
            })}
          >
            {children}
          </div>
        </Link>
      )
    }
    return (
      <div
        data-testid={'default-card'}
        className={clsx(allClassNames, {
          [classes.answerBorder]: answerBorder !== 'none',
        })}
        {...other}
      >
        <div
          className={clsx({
            [classes.cardInner]: cardInner === 'default',
            [classes.cardInnerHV32]: cardInner === 'HV32',
            [classes.cardInnerH32V24]: cardInner === 'H32V24',
          })}
        >
          {children}
        </div>
      </div>
    )
  }

  return answerBorder !== 'none' ? (
    <div>
      {renderComponent()}
      <div className={classes.answerBorder1}></div>
      {answerBorder === 'double' && (
        <div className={classes.answerBorder2}></div>
      )}
    </div>
  ) : (
    renderComponent()
  )
}

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.oneOf(['BaseCard', 'StageCard', 'SplitStageCard', 'default']),
  bar: PropTypes.oneOf(['yellow', 'red', 'grey']),
  barOrientation: PropTypes.oneOf(['right', 'left']),
  bind: PropTypes.oneOf(['default', 'left', 'leftAuto', 'right', 'split']),
  transparentCard: PropTypes.bool,
  stage: PropTypes.bool,
  answerBorder: PropTypes.oneOf(['none', 'single', 'double']),
  noMarginBottom: PropTypes.bool,
  cardInner: PropTypes.oneOf(['default', 'H32V24', 'HV32']),
  link: PropTypes.string,
}

Card.defaultProps = {
  bar: 'yellow',
  type: 'default',
  barOrientation: 'right',
  bind: 'default',
  cardInner: 'default',
  answerBorder: 'none',
  stage: false,
}

export default Card
